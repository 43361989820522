import React from 'react';
import LandingPageView4 from 'views/LandingPageView4';
import Main from 'layouts/Main';
import WithLandingPageLayout from 'WithLandingPageLayout';
import { Helmet } from 'react-helmet';

// DATA

const title = 'Save on Cloud Storage Pricing For Your Law Firm';
const content = {

  hero: {
    title: "",
    emphasized: "Lower Your Law Firm's Cloud Storage Costs",
    subtitle: "Shop the best Cloud Storage pricing for your law firm's needs.",
    titleVariant: "h2",
    subtitleVariant: "h4",      
  },

  section1_bottom: "",

  providersInfo: {
    default_provider: "law-firm",
    title: "Shop the lowest prices per TB on the best cloud storage platforms for law firms",
    subtitle: "Including Azure Blob, Google Cloud Storage and AWS S3 Storage",
    providers: [
        {
            name: "Law Firms",
            logo: "/images/small-business.svg",
            id: "law-firm"
        },

        {
            name: "AWS S3 Storage",
            logo: "https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg",
            id: "aws"
        },

        {
            name: "Google Cloud Storage (GCP)",
            logo: "https://upload.wikimedia.org/wikipedia/commons/0/01/Google-cloud-platform.svg",
            id: "google"
        },

        {
            name: "Microsoft Azure Storage",
            logo: "/images/Azure.svg",
            id: "azure"
        }
    ]
  },

  quoteForm2: {
    quote_provider:"any",
    title: "Save 60% on your law firm's cloud storage prices",  
    subtitle: "Get the perfect match of providers from our AI and human curators",
    titleVariant: "h4",
    subtitleVariant: "h5",
    refPage: "small-business-current-pricing-may22",   
    
    quote_skin: {
        quoteTitleLeft : "Start Saving on your",
        quoteTitleRight : " Law Firm's Cloud Storage!"
    },
    
    videoSection: {
        title: "Compare and Save on Cloud Storage Costs",
        subtitle: "WindRate is a new way to shop for cloud storage, its the same storage you use now but at incredible discounts",
        callToAction: {
            buttonText: "Get a Quote Now",
            href: "/get-a-quote"
        },

        videoFrame: {
            title: "video",
            src: "https://www.youtube.com/embed/j-tCtk4e6Ys?autoplay=1",
            allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        }
    }
  },

  quoteForm: {
    quote_provider:"any",
    title: "Save 60% per TB on Azure Blob storage prices from leading providers",  
    subtitle: "Get the perfect match of providers from our AI and human curators",
    titleVariant: "h4",
    subtitleVariant: "h5",
    refPage: "law-firm-current-pricing-july22"
  },

  subText: {
    title: "Same Azure Blob Storage",
    emphasized: "but cheaper",
    subtitle: "Get great pricing on Google Gloud Standard, Coldline, Nearline, Archive storage.<br><br>Compare GCP vs AWS and GCP vs Azure in a single click.<br><br>We will take your requirements and source competitive quotes from leading partners and Managed Service Providers, saving you up to 60% on the same Azure Blob storage you use today. You decide who to deal with and when, your information stays anonymous in the meantime.<br>",
    subtitleEmphasized: "Request a quote today, it’s simple and free!",
    titleVariant: "h5",
    subtitleVariant: "h6",     
  }

};

// END DATA

const LandingPage = () => {
  return (
  <>
  <Helmet
    defaultTitle="WindRate | #1 Cloud Storage Pricing Engine"
    title={title}
    titleTemplate="%s | WindRate.com"
  />     
    <WithLandingPageLayout
      data={content}
      component={LandingPageView4}
      layout={Main}
    />
    </>
  )
};
 
 export default LandingPage;
 